#sequence {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  width: 100%;
  justify-content: space-between;
  background-color: black; }
  #sequence .control-container {
    margin-top: clamp(25px, 10vh, 50px);
    z-index: 150;
    width: 100%;
    justify-content: space-between;
    padding-left: clamp(25px, 10vw, 50px);
    padding-right: clamp(25px, 10vw, 50px); }
  #sequence .text-background-overlay {
    z-index: 150;
    width: 100%;
    padding-top: 50px;
    padding-left: clamp(25px, 10vw, 50px);
    padding-right: clamp(25px, 10vw, 50px);
    padding-bottom: clamp(30px, 8vh, 80px);
    min-height: calc(50px + clamp(30px, 8vh, 80px) + 2 * 1.2 * 2rem);
    display: flex;
    background: linear-gradient(0deg, var(--text-background-color) 0%, rgba(0, 0, 0, 0) 100%);
    mix-blend-mode: multiply;
    position: absolute;
    left: 0;
    bottom: 0; }
  #sequence .text-container {
    padding-top: 50px;
    padding-left: clamp(25px, 10vw, 50px);
    padding-right: clamp(25px, 10vw, 50px);
    padding-bottom: clamp(30px, 8vh, 80px);
    color: white;
    z-index: 200;
    font-size: clamp(1.5rem, 4vh, 2rem);
    line-height: 1.2;
    font-weight: 700;
    width: 90%;
    max-width: 800px;
    text-align: center;
    margin: auto;
    margin-bottom: 0;
    opacity: 0;
    transition: opacity 250ms linear; }
    #sequence .text-container.show {
      opacity: 1; }
  @media (max-width: 767px) {
    #sequence .text-container {
      width: 100%;
      padding-left: calc(clamp(20px, 5vw, 50px) + 2 * (clamp(2rem, 2.5vh, 80px) + 20px));
      padding-right: calc(clamp(20px, 5vw, 50px) + 2 * (clamp(2rem, 2.5vh, 80px) + 20px)); } }
  #sequence .skip-sequence-button {
    border: 1px solid transparent;
    transition: transform 150ms linear;
    transform-origin: center right;
    font-size: 22px; }
    #sequence .skip-sequence-button span#carret {
      transition: padding-left 150ms linear; }
    #sequence .skip-sequence-button:hover span#carret {
      padding-left: 15px; }
    #sequence .skip-sequence-button.noauto span#carret {
      margin-left: 10px;
      vertical-align: middle; }
      #sequence .skip-sequence-button.noauto span#carret img {
        rotate: -90deg; }
    #sequence .skip-sequence-button.noauto:hover span#carret img {
      animation: bounceNext 1000ms linear; }
@keyframes bounceNext {
  0%,
    50%,
    100% {
    transform: translateY(0); }
  25%,
    75% {
    transform: translateY(-20px); } }
  #sequence .additional-image {
    z-index: 100;
    flex: 1;
    min-height: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 250ms linear; }
    #sequence .additional-image.show {
      opacity: 1; }
    #sequence .additional-image img {
      display: block;
      margin: auto;
      width: 90%;
      height: 90%;
      object-fit: contain;
      object-position: center; }
  #sequence .background,
  #sequence .foreground {
    position: absolute;
    z-index: 50;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1; }
    #sequence .background .background-image,
    #sequence .foreground .background-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      animation-fill-mode: forwards;
      animation-timing-function: linear; }
    #sequence .background .background-effect,
    #sequence .foreground .background-effect {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      animation-fill-mode: forwards;
      animation-timing-function: linear; }
  #sequence .foreground {
    z-index: 100; }
    #sequence .foreground .background-image {
      object-fit: contain; }
  #sequence .controller {
    position: absolute;
    bottom: clamp(20px, 5vh, 50px);
    left: clamp(20px, 5vw, 50px);
    z-index: 999; }
    #sequence .controller button {
      height: clamp(2rem, 2.5vh, 80px);
      width: clamp(2rem, 2.5vh, 80px);
      margin: 0 10px; }
    #sequence .controller .sound.on {
      background-image: url("assets/img/icon_son.png"); }
      #sequence .controller .sound.on:hover {
        background-image: url("assets/img/icon_son_hoover.png"); }
    #sequence .controller .sound.off {
      background-image: url("assets/img/icon_son_off.png"); }
      #sequence .controller .sound.off:hover {
        background-image: url("assets/img/icon_son_off_hoover.png"); }
    #sequence .controller .play-pause.playing {
      background-image: url("assets/img/icon_play.png"); }
      #sequence .controller .play-pause.playing:hover {
        background-image: url("assets/img/icon_play_hoover.png"); }
    #sequence .controller .play-pause.paused {
      background-image: url("assets/img/icon_pause.png"); }
      #sequence .controller .play-pause.paused:hover {
        background-image: url("assets/img/icon_pause_hoover.png"); }
