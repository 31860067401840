.main-choose {
  overflow: hidden; }
  .main-choose .background-container {
    width: 100vw;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    position: relative;
    visibility: hidden;
    opacity: 0;
    transition: opacity 500ms linear;
    --actual-screen-height: 90vh;
    --map-height: 200vh;
    --map-width: calc(var(--map-height) * 1.48436);
    --map-x-center: 0.25;
    --map-y-center: 0.65;
    --map-x-shift: calc(50vw - (var(--map-x-center) * var(--map-width)));
    --map-y-shift: calc(var(--actual-screen-height) / 2 - (var(--map-y-center) * var(--map-height))); }
    .main-choose .background-container.show {
      visibility: visible;
      opacity: 1; }
  .main-choose .background-map {
    transform: translate(calc(var(--map-x-shift)), calc(var(--map-y-shift)));
    height: var(--map-height);
    width: var(--map-width);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10; }
    .main-choose .background-map img {
      width: auto;
      height: 100%; }
  .main-choose .button-country {
    top: 0;
    left: 0;
    position: absolute;
    padding: 0; }
    .main-choose .button-country img {
      position: relative;
      top: 0;
      left: 0;
      z-index: 30; }
      .main-choose .button-country img.on-hover {
        position: absolute;
        z-index: 50;
        opacity: 0;
        transition: opacity 250ms linear; }
    .main-choose .button-country:hover img.on-hover, .main-choose .button-country.hovered img.on-hover {
      opacity: 1; }
    .main-choose .button-country:hover .shoes-container, .main-choose .button-country.hovered .shoes-container {
      transform: translateY(-30%); }
    .main-choose .button-country .shoes-container {
      position: absolute;
      transition: transform 250ms linear; }
      .main-choose .button-country .shoes-container img {
        width: auto;
        height: 10vh; }
  .main-choose .button-congo {
    height: calc(var(--map-height) * 0.13);
    left: 17.2%;
    top: 71%; }
    .main-choose .button-congo .shoes-container {
      bottom: 10%;
      right: 100%; }
      .main-choose .button-congo .shoes-container img {
        width: auto;
        height: 10vh; }
  .main-choose .button-erythree {
    height: calc(var(--map-height) * 0.04);
    left: 28.6%;
    top: 62.75%; }
    .main-choose .button-erythree .shoes-container {
      bottom: 0%;
      right: 120%; }
      .main-choose .button-erythree .shoes-container img {
        height: 12vh; }
  .main-choose .button-syrie {
    height: calc(var(--map-height) * 0.04);
    left: 28.3%;
    top: 48.15%; }
    .main-choose .button-syrie .shoes-container {
      top: 60%;
      left: 80%; }
      .main-choose .button-syrie .shoes-container img {
        height: 12vh; }
  .main-choose .info {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999;
    font-weight: 700;
    padding-bottom: clamp(30px, 5vh, 50px);
    padding-right: clamp(30px, 5vw, 50px); }
    .main-choose .info img {
      display: inline-block;
      vertical-align: middle; }
    .main-choose .info button {
      height: clamp(25px, 4vh, 80px);
      width: clamp(25px, 4vh, 80px);
      margin: 0 10px;
      vertical-align: middle; }
    .main-choose .info .sound.on {
      background-image: url("assets/img/icon_son.png"); }
      .main-choose .info .sound.on:hover {
        background-image: url("assets/img/icon_son_hoover.png"); }
    .main-choose .info .sound.off {
      background-image: url("assets/img/icon_son_off.png"); }
      .main-choose .info .sound.off:hover {
        background-image: url("assets/img/icon_son_off_hoover.png"); }
  .main-choose .story-container {
    opacity: 1;
    transition: opacity 300ms linear;
    z-index: 100;
    text-align: left;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    padding: 50px;
    color: black;
    width: 80vw;
    max-width: 600px;
    left: max(10vw, calc((100vw - 600px) / 2));
    top: 10vh;
    max-height: 80vh; }
    .main-choose .story-container .scroll-container {
      opacity: 0;
      transition: opacity 0ms linear;
      max-height: calc(80vh - 100px);
      overflow: auto;
      /* Track */
      /* Handle */ }
      .main-choose .story-container .scroll-container.show {
        transition-duration: 1000ms;
        opacity: 1; }
      .main-choose .story-container .scroll-container::-webkit-scrollbar {
        width: clamp(5px, 0.5vw, 10px); }
      .main-choose .story-container .scroll-container::-webkit-scrollbar-track {
        background: transparent; }
      .main-choose .story-container .scroll-container::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: clamp(5px, 0.5vw, 10px); }
    .main-choose .story-container .story-title {
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 10px; }
    .main-choose .story-container .story-city {
      text-transform: uppercase; }
    .main-choose .story-container .story-text {
      font-weight: 700;
      font-size: 1.4rem; }
  .main-choose .start-button {
    padding: 15px 30px;
    font-size: 1rem;
    margin-bottom: 10px; }
  .main-choose .info-box-enter {
    opacity: 0; }
  .main-choose .info-box-enter-active {
    opacity: 1;
    transition: opacity 300ms; }
  .main-choose .info-box-exit {
    opacity: 1; }
  .main-choose .info-box-exit-active {
    opacity: 0;
    transition: opacity 300ms; }
  .main-choose .more-container {
    z-index: 999;
    position: absolute;
    left: clamp(30px, 5vw, 50px);
    bottom: clamp(30px, 5vh, 50px); }
  @media screen and (min-height: 768px) {
    .main-choose .story-container {
      max-height: 65vh;
      max-width: 500px; }
      .main-choose .story-container .scroll-container {
        max-height: calc(65vh - 100px); }
    .main-choose .story-container.Sifa {
      top: auto;
      bottom: 30%;
      left: calc((100vw - 600px) * 0.2); }
    .main-choose .story-container.Senait {
      top: 20%;
      left: calc((100vw - 600px) * 0.4); }
    .main-choose .story-container.Hassan {
      top: 5%;
      left: auto;
      right: 45%; } }
