.main-journey {
  position: relative; }
  .main-journey .loading-container {
    position: absolute;
    z-index: 8000;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms linear; }
    .main-journey .loading-container.loading {
      opacity: 1; }
  .main-journey .loading-spinner {
    height: auto;
    width: clamp(80px, 10vw, 150px);
    margin: 20px;
    margin-bottom: 30px;
    animation: vertical-slide 1.2s ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 0.1s; }
  .main-journey .loading-dots .loading-dot {
    margin-left: 15px;
    margin-right: 15px;
    object-fit: contain;
    object-position: center;
    width: 20px;
    height: auto;
    animation: vertical-slide 1.2s ease-in-out 0s infinite, change-opacity 1.2s ease-in-out infinite; }
  .main-journey .loading-dots .loading-dot:nth-child(2) {
    animation-delay: .1s; }
  .main-journey .loading-dots .loading-dot:nth-child(3) {
    animation-delay: .2s; }
@keyframes vertical-slide {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-20px); }
  100% {
    transform: translateY(0); } }
@keyframes change-opacity {
  0% {
    opacity: 1; }
  50% {
    opacity: .3; }
  100% {
    opacity: 1; } }
