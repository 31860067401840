#info {
  padding-left: clamp(25px, 10vw, 50px);
  padding-right: clamp(25px, 10vw, 50px);
  padding-top: clamp(25px, 10vh, 50px);
  padding-bottom: clamp(15px, 5vh, 30px);
  height: auto;
  height: 100%;
  overflow: auto;
  width: 100%;
  justify-content: space-between; }
  #info .control-container {
    width: 100%;
    justify-content: space-between; }
  #info .content {
    flex: 1;
    padding: clamp(15px, 5vh, 30px) 0 0 0; }
    #info .content .content-text {
      font-weight: 700;
      font-size: clamp(1.4rem, 4vh, 2rem); }
  #info .actions-container {
    padding-top: clamp(25px, 3vh, 50px);
    padding-bottom: clamp(25px, 3vh, 50px);
    text-align: center;
    margin-top: auto;
    margin-bottom: auto; }
    #info .actions-container .action-text {
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem;
      padding-bottom: min(4vh, 50px); }
    #info .actions-container .action-button {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 900;
      padding: 15px;
      width: 50%;
      max-width: 350px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      margin-left: auto;
      margin-right: auto; }
      #info .actions-container .action-button .icon {
        width: 80%;
        max-width: 80px;
        position: relative;
        margin: auto;
        margin-bottom: 15px; }
        #info .actions-container .action-button .icon img {
          object-fit: contain;
          object-position: top;
          height: 100%;
          width: 100%;
          opacity: 1;
          transition: opacity 250ms linear; }
        #info .actions-container .action-button .icon img.on-hover {
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          transition: opacity 250ms linear; }
      #info .actions-container .action-button:hover .icon img {
        opacity: 0; }
      #info .actions-container .action-button:hover .icon img.on-hover {
        opacity: 1; }
  #info .action-next {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    margin-bottom: clamp(50px, 8vh, 150px); }
    #info .action-next button {
      width: 80%;
      max-width: 500px;
      font-weight: 700;
      font-size: 1.2rem; }
    #info .action-next .go-next-button:disabled {
      pointer-events: none;
      opacity: 0; }
  #info .fixed-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0; }
  #info .actionsAnimation-enter {
    opacity: 0;
    transform: scale(0.9); }
  #info .actionsAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1000ms, transform 1000ms; }
  #info .actionsAnimation-exit {
    opacity: 1; }
  #info .actionsAnimation-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1000ms, transform 1000ms; }

.info-dialog-actions {
  padding: 20px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .info-dialog-actions .close-dialog-button .close-icon {
    width: 40px; }

.imageDialog .MuiBackdrop-root {
  background-color: #ffffffcc; }
.imageDialog .info-dialog-actions .close-dialog-button .close-icon {
  filter: invert(1); }

.full-screen-dialog {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent !important; }

.info-dialog-text {
  height: 90%;
  max-height: min(70vh, calc(905 / 855 * min(60vw, 700px))); }
  .info-dialog-text .text-container {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 40px;
    width: 80%;
    max-width: min(60vw, 700px);
    height: 100%;
    color: black; }
    .info-dialog-text .text-container .text-content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
      .info-dialog-text .text-container .text-content::-webkit-scrollbar {
        display: none; }
    .info-dialog-text .text-container .inline-title {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 15px;
      margin-top: 15px;
      font-weight: 700; }
    .info-dialog-text .text-container .title {
      font-size: 2rem;
      margin-bottom: 15px;
      font-weight: 700;
      line-height: 1; }
      .info-dialog-text .text-container .title .subtitle {
        font-size: 1.4rem;
        font-weight: 500; }
    .info-dialog-text .text-container .text {
      font-size: 1.3rem; }
    .info-dialog-text .text-container a {
      color: #5a36e4;
      text-decoration: none; }
  .info-dialog-text .scroll-bar {
    visibility: hidden;
    width: 10px;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 20px;
    position: relative; }
    .info-dialog-text .scroll-bar.visible {
      visibility: visible; }
    .info-dialog-text .scroll-bar .scroll-handle-container {
      top: max(5%, 20px);
      height: calc(100% - max(5%, 20px) - max(5%, 20px));
      width: 100%;
      position: relative; }
    .info-dialog-text .scroll-bar .scroll-handle {
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: 0px;
      transition: top 50ms linear; }
    .info-dialog-text .scroll-bar .range-handle {
      -webkit-appearance: none;
      /* Override default CSS styles */
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 10px;
      transform-origin: 10px 0;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      transform: rotate(90deg);
      background: transparent; }
      .info-dialog-text .scroll-bar .range-handle::-webkit-slider-thumb {
        -webkit-appearance: none;
        /* Override default look */
        appearance: none;
        border-radius: 50%;
        width: 20px;
        /* Set a specific slider handle width */
        height: 20px;
        /* Slider handle height */
        background-image: url("assets/img/scollBarHandle-min.png");
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        /* Cursor on hover */ }
      .info-dialog-text .scroll-bar .range-handle::-moz-range-thumb {
        -webkit-appearance: none;
        /* Override default look */
        appearance: none;
        border-radius: 50%;
        border: none;
        width: 20px;
        /* Set a specific slider handle width */
        height: 20px;
        /* Slider handle height */
        background-image: url("assets/img/scollBarHandle-min.png");
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        /* Cursor on hover */ }

.hytPlayerWrapOuter {
  height: 100%;
  width: 100%; }

.hytPlayerWrap {
  display: inline-block;
  position: relative; }
  .hytPlayerWrap:after {
    opacity: 0;
    transition: opacity 150ms linear;
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center; }

.hytPlayerWrap.ended::after {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
  background-size: 64px 64px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==); }

.hytPlayerWrap.paused::after {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
  background-size: 40px 40px;
  background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEiIHdpZHRoPSIxNzA2LjY2NyIgaGVpZ2h0PSIxNzA2LjY2NyIgdmlld0JveD0iMCAwIDEyODAgMTI4MCI+PHBhdGggZD0iTTE1Ny42MzUgMi45ODRMMTI2MC45NzkgNjQwIDE1Ny42MzUgMTI3Ny4wMTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+); }
