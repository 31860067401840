#mapContainer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  height: 100%;
  width: 100%;
  overflow-y: auto; }
  #mapContainer::-webkit-scrollbar {
    display: none; }
  #mapContainer #map {
    height: 100%;
    width: 100%; }
  #mapContainer .map-image {
    width: 100%;
    height: 100%; }
    #mapContainer .map-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  #mapContainer .map-scroller {
    width: 100%;
    height: 5px; }
  #mapContainer .arrow-down-container {
    cursor: pointer;
    position: absolute;
    bottom: clamp(20px, 3vh, 40px);
    right: clamp(40px, 8vh, 80px); }
    #mapContainer .arrow-down-container img {
      height: clamp(40px, 8vh, 80px);
      width: auto;
      animation: bounceArrow 1000ms linear 2; }
@keyframes bounceArrow {
  0% {
    transform: translateY(0); }
  30% {
    transform: translateY(-5vh); }
  100% {
    transform: translateY(0); } }
