.rotate-screen-container {
  width: 100%;
  height: 100%;
  position: relative; }
  .rotate-screen-container.show {
    width: 100vw;
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    overflow: hidden; }
    .rotate-screen-container.show .rotate-screen {
      pointer-events: all;
      opacity: 1; }

.rotate-screen {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  color: white; }

.rotate-content {
  width: 60%;
  max-width: 400px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700; }
  .rotate-content i {
    display: block;
    font-size: 2.5rem;
    margin: 1rem 0; }
