@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?e721732e27ab58b167fd4e7eb36e4ee3") format("truetype"),
url("./flaticon.woff?e721732e27ab58b167fd4e7eb36e4ee3") format("woff"),
url("./flaticon.woff2?e721732e27ab58b167fd4e7eb36e4ee3") format("woff2"),
url("./flaticon.eot?e721732e27ab58b167fd4e7eb36e4ee3#iefix") format("embedded-opentype"),
url("./flaticon.svg?e721732e27ab58b167fd4e7eb36e4ee3#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-screen-rotation-button:before {
    content: "\f101";
}
