/* IMPORT FONTS */
@font-face {
  font-family: 'GravurCondensed';
  src: url("./assets/fonts/GravurCondensed/GravurCondensed-Thin.otf") format("truetype");
  font-weight: 100; }
@font-face {
  font-family: 'GravurCondensed';
  src: url("./assets/fonts/GravurCondensed/GravurCondensed-Regular.otf") format("truetype");
  font-weight: 400; }
@font-face {
  font-family: 'GravurCondensed';
  src: url("./assets/fonts/GravurCondensed/GravurCondensed-Bold.otf") format("truetype");
  font-weight: 700; }
@font-face {
  font-family: 'GravurCondensed';
  src: url("./assets/fonts/GravurCondensed/GravurCondensed-Black.otf") format("truetype");
  font-weight: 900; }
html,
body,
#root {
  height: 100%; }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: 'GravurCondensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: white;
  font-size: 1rem;
  --variable-height: 100vh;
  min-height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  height: var(--variable-height);
  overflow: hidden;
  min-width: 100vw; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* DISPLAY */
.full-width {
  min-width: 100vw; }

.full-height {
  min-height: 100%; }

.fixed-width {
  width: 100vw; }

.fixed-height {
  height: 100%; }

.with-scroll {
  overflow: auto; }

.background-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.background-100p {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat; }

/* FLEX */
.flex {
  display: flex;
  flex-direction: row; }

.flex-column {
  flex-direction: column; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: flex-end; }

.align-items-start {
  align-items: flex-start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: flex-end; }

.flex-wrap {
  flex-wrap: wrap; }

.align-self-end {
  align-self: flex-end; }

.flex-order-1 {
  order: 1; }

.flex-order-2 {
  order: 2; }

.flex-order-3 {
  order: 3; }

.content-scroller {
  width: 100%;
  height: 100%;
  overflow-y: auto; }

/* CURSOR */
.mouse-catcher {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999; }

.mouse-tracker {
  position: fixed;
  z-index: 9999;
  height: 12px;
  width: 12px;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  background-image: url("./assets/img/curseur_souris.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  top: 50px;
  left: 50px; }

/* BUTTON */
button {
  background: none;
  border: none;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: none; }

.choose-lang-button {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid transparent; }

.go-back-home-button {
  font-size: 22px; }
  .go-back-home-button img {
    display: inline-block;
    vertical-align: middle;
    height: 26.4px;
    width: 26.4px;
    object-fit: contain;
    object-position: center; }
    .go-back-home-button img.on-hover {
      display: none; }
  .go-back-home-button .info {
    display: inline-block;
    vertical-align: middle;
    max-width: 0;
    overflow: hidden;
    transition: max-width 250ms linear; }
  .go-back-home-button:hover .info {
    overflow: visible;
    max-width: 100px; }
  .go-back-home-button:hover img {
    display: none; }
    .go-back-home-button:hover img.on-hover {
      display: inline-block; }

button.with-hover img {
  display: inline-block;
  vertical-align: middle;
  object-fit: contain;
  object-position: center; }
  button.with-hover img.on-hover {
    display: none; }
button.with-hover:hover img {
  display: none; }
  button.with-hover:hover img.on-hover {
    display: inline-block; }

button.bloc-noir {
  background-image: url("./assets/img/bloc_noir-min.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  color: white;
  font-weight: 700; }
  button.bloc-noir:not(:disabled):hover {
    background-image: url("./assets/img/block_noir_hoover-min.png"); }

body.junior button.bloc-noir:not(:disabled):hover {
  background-image: url("./assets/img/block_pink_hoover-min.png"); }

.small-circular-button {
  width: clamp(2rem, 2.5vh, 80px);
  height: clamp(2rem, 2.5vh, 80px);
  line-height: clamp(2rem, 2.5vh, 80px);
  text-align: center; }
  .small-circular-button.more-button {
    background-image: url("assets/img/icon_plus.png"); }
    .small-circular-button.more-button:hover {
      background-image: url("assets/img/icon_plus_hoover.png"); }
  .small-circular-button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center; }
  .small-circular-button:hover {
    transform: scale(1.2); }

/* FILE LOADER*/
.file-loader {
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  visibility: hidden; }

.audio-container {
  height: 100%; }

.audio-container.request .audio-container-page {
  opacity: 1;
  pointer-events: all; }

.audio-container-page {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms linear;
  padding: min(10vh, 50px) min(15vw, 80px);
  /* 2 */ }
  .audio-container-page .title {
    font-size: 2rem;
    font-weight: 900;
    margin-top: auto;
    width: 60%;
    max-width: 800px; }
    .audio-container-page .title img {
      width: 100%; }
  .audio-container-page .start-container {
    text-align: center; }
  @media (orientation: portrait) {
    .audio-container-page .start-container {
      flex-direction: column; } }
  .audio-container-page .start-button {
    height: clamp(60px, 15vh, 200px);
    width: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .audio-container-page .start-button .circular-button {
      margin-bottom: 5px; }
    .audio-container-page .start-button .hover-image,
    .audio-container-page .start-button .base-image {
      width: auto;
      height: 70%;
      object-fit: contain;
      object-position: center;
      position: relative;
      margin-left: auto;
      margin-right: auto; }
    .audio-container-page .start-button .hover-image {
      display: none; }
    .audio-container-page .start-button:hover .base-image {
      display: none; }
    .audio-container-page .start-button:hover .hover-image {
      display: block; }
    .audio-container-page .start-button:hover ~ .arrow-background {
      transform: rotate(270deg); }
    .audio-container-page .start-button.arrow-background {
      margin: 10%;
      transform-origin: center;
      transition: transform 250ms linear; }
      .audio-container-page .start-button.arrow-background .fixed-image {
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto; }
  .audio-container-page .start-text {
    transition: transform 250ms linear;
    text-transform: uppercase;
    font-weight: 700; }
  .audio-container-page .more-container {
    align-self: flex-start; }

/* UTILS */
.background-underlined {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom; }
  .background-underlined.very-small {
    background-image: url("assets/img/1_trait_tres_court.png"); }
  .background-underlined.small {
    background-image: url("assets/img/2_trait_court.png"); }
  .background-underlined.medium {
    background-image: url("assets/img/3_trait_moyen.png"); }
  .background-underlined.large {
    background-image: url("assets/img/4_trait_long.png"); }
  .background-underlined.very-large {
    background-image: url("assets/img/5_trait_tres_long.png"); }
  .background-underlined.white.large {
    background-image: url("assets/img/3_trait_long_white.png"); }

/* ANIMATIONS */
@keyframes scale10 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }
@keyframes scale20 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }
@keyframes scale30 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.3); } }
@keyframes scale40 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.4); } }
@keyframes scaleDown20 {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }
@keyframes scale100To107 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.07); } }
@keyframes scale107To120 {
  0% {
    transform: scale(1.07); }
  100% {
    transform: scale(1.2); } }
@keyframes scale100To74 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.74); } }
@keyframes scale74To50 {
  0% {
    transform: scale(0.74); }
  100% {
    transform: scale(0.5); } }
@keyframes scale100To150 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.5); } }
@keyframes scale75To91 {
  0% {
    transform: scale(0.75); }
  100% {
    transform: scale(0.91); } }
@keyframes scale91To100 {
  0% {
    transform: scale(0.91); }
  100% {
    transform: scale(1); } }
@keyframes animationSenaitBateau {
  0% {
    opacity: 1;
    transform: translate(80vw); }
  100% {
    opacity: 1;
    transform: translate(-100vw); } }
@keyframes opacityNoneToFull {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes opacityFullToNone {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes opacityNoneTo60 {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.6; } }
@keyframes opacity60ToFull {
  0% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }
@keyframes opacityNoneTo80 {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.8; } }
@keyframes opacity80ToFull {
  0% {
    opacity: 0.8; }
  100% {
    opacity: 1; } }
@keyframes translateXM10vhTo1vh {
  0% {
    transform: translateX(-10vh); }
  100% {
    transform: translateX(1vh); } }
@keyframes translateX1vhTo10vh {
  0% {
    transform: translateX(1vh); }
  100% {
    transform: translateX(10vh); } }
@keyframes handAnimationPart1 {
  0% {
    transform: scale(0.75) translateX(-10vh); }
  100% {
    transform: scale(0.91) translateX(1vh); } }
@keyframes handAnimationPart2 {
  0% {
    transform: scale(0.91) translateX(1vh); }
  100% {
    transform: scale(1) translateX(10vh); } }
@keyframes translateX0To20vw {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(20vw); } }
@keyframes translateX0ToM20vw {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-20vw); } }
@keyframes animationSifa2 {
  0% {
    transform: scale(var(--scale)) translateX(0) translateY(calc(-1 / 2 * var(--scale) * var(--imageHeight) + 50vh)); }
  100% {
    transform: scale(1) translateX(-40vw) translateY(0); } }
@keyframes animationHassanTanks {
  0% {
    opacity: 0;
    transform: scale(0.7) translate(100%, -20vh); }
  29% {
    opacity: 1;
    transform: scale(0.8) translate(46.4%, -4.4vh); }
  100% {
    opacity: 1;
    transform: scale(1) translate(-60%, 40vh); } }
@keyframes hassanPhoneAnimation1 {
  0% {
    opacity: 0;
    transform: scale(0.8); }
  35% {
    opacity: 1;
    transform: scale(0.87); }
  100% {
    opacity: 1;
    transform: scale(1); } }
@keyframes hassanPhoneAnimation2 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.8); } }
@keyframes animationHassanSent1 {
  0% {
    transform: scale(0.4) rotate(-30deg); }
  100% {
    transform: scale(0.8) rotate(12deg); } }
@keyframes animationHassanSent2 {
  0% {
    transform: scale(0.75) rotate(0); }
  100% {
    transform: scale(1) rotate(10deg); } }
@keyframes animationHassanSent3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.25); } }
@keyframes animationHassanSent4 {
  0% {
    opacity: 0;
    transform: scale(1) rotate(0); }
  10% {
    opacity: 1; }
  100% {
    opacity: 1;
    transform: scale(0) rotate(360deg); } }
@keyframes animationHassanFamille {
  0% {
    opacity: 1;
    transform: scale(1) rotate(0); }
  100% {
    opacity: 1;
    transform: scale(0) rotate(450deg); } }
@keyframes animationHassanWhatsapp {
  0% {
    opacity: 0;
    transform: scale(1) rotate(0); }
  5% {
    opacity: 1;
    transform: scale(1) rotate(0); }
  100% {
    opacity: 1;
    transform: scale(0) rotate(-60deg); } }
@keyframes animationSenait08_A {
  0% {
    transform: translate(-20%, 20%); }
  100% {
    transform: translate(20%, -40%); } }
