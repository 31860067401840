.main-homepage {
  overflow-y: auto; }
  .main-homepage .content-container {
    padding: min(10vh, 50px) min(15vw, 80px);
    min-height: 100%; }
  .main-homepage .circular-button {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center; }
    .main-homepage .circular-button img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center; }
  .main-homepage .title {
    font-size: 2rem;
    font-weight: 900;
    margin-top: auto;
    width: 60%;
    max-width: 800px; }
    .main-homepage .title img {
      width: 100%; }
  .main-homepage .choose-lang-container {
    margin-bottom: auto;
    text-align: center;
    width: 80%;
    max-width: 800px; }
  .main-homepage .choose-lang-button {
    width: 40%;
    max-width: 400px;
    text-align: center;
    margin: 0 5%;
    font-size: 1.2rem;
    transition: transform 150ms ease; }
    .main-homepage .choose-lang-button .circular-button {
      margin: auto;
      margin-bottom: 10px; }
    .main-homepage .choose-lang-button:hover {
      transform: scale(1.1); }
  .main-homepage .choose-lang-text {
    font-weight: 700; }
  .main-homepage .more-container {
    align-self: flex-start;
    width: 100%; }
    .main-homepage .more-container .go-to-review {
      margin-left: auto;
      width: min(260px, 40vw);
      text-align: right; }
      .main-homepage .more-container .go-to-review img {
        object-fit: contain;
        width: 100%; }
  .main-homepage .choose-junior {
    padding: 5px 10px;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 1.1rem;
    transition: transform 250ms ease-in-out; }
    .main-homepage .choose-junior:hover {
      background-color: white;
      color: black;
      transform: scale(1.1); }
