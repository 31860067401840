#epilogue {
  height: 100%;
  width: 100%; }
  #epilogue .background-image {
    min-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    #epilogue .background-image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      opacity: 0;
      transition: opacity 250ms linear; }
      #epilogue .background-image img.show {
        opacity: 1; }
  #epilogue .button-container {
    position: relative;
    width: 40vw;
    padding-left: 0.8vw; }
  #epilogue button.button-terminer {
    margin-top: 20px;
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: black;
    border-radius: 10px;
    padding: 10px 20px; }
    #epilogue button.button-terminer span {
      margin-left: 0;
      transition: margin-left 150ms ease; }
    #epilogue button.button-terminer:hover span {
      margin-left: 15px; }
  #epilogue .text-container {
    color: white;
    background-image: url("assets/img/epiloge_black_background.png");
    background-size: 100% 100%;
    background-position: center;
    padding: 0;
    position: relative;
    width: 40vw;
    max-height: 60vh;
    opacity: 0;
    transition: opacity 250ms linear;
    align-items: flex-start; }
    #epilogue .text-container.show {
      padding: clamp(50px, 6vh, 80px);
      opacity: 1; }
    #epilogue .text-container .scroller {
      height: 100%;
      overflow: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */ }
      #epilogue .text-container .scroller::-webkit-scrollbar {
        display: none; }
    #epilogue .text-container .title {
      font-size: clamp(2rem, 4vh, 2.8rem);
      font-weight: 700;
      padding-bottom: 30px;
      line-height: 1; }
    #epilogue .text-container .text {
      font-size: clamp(1.4rem, 2.8vh, 2rem);
      font-weight: 700;
      flex-grow: 1;
      padding-right: 5px; }
  #epilogue .scroll-bar {
    visibility: hidden;
    width: 10px;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 20px;
    position: absolute;
    right: calc(clamp(50px, 6vh, 80px) / 2);
    top: clamp(50px, 6vh, 80px);
    height: calc(100% - 2*clamp(50px, 6vh, 80px)); }
    #epilogue .scroll-bar.visible {
      visibility: visible; }
    #epilogue .scroll-bar .scroll-handle-container {
      top: max(5%, 20px);
      height: calc(100% - max(5%, 20px) - max(5%, 20px));
      width: 100%;
      position: relative; }
    #epilogue .scroll-bar .scroll-handle {
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: 0px;
      transition: top 50ms linear; }
    #epilogue .scroll-bar .range-handle {
      -webkit-appearance: none;
      /* Override default CSS styles */
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 10px;
      transform-origin: 10px 0;
      transform: rotate(90deg);
      background: transparent; }
      #epilogue .scroll-bar .range-handle::-webkit-slider-thumb {
        -webkit-appearance: none;
        /* Override default look */
        appearance: none;
        border-radius: 50%;
        border: none;
        width: 20px;
        /* Set a specific slider handle width */
        height: 20px;
        /* Slider handle height */
        background-image: url("assets/img/scollBarHandle-min.png");
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        /* Cursor on hover */ }
      #epilogue .scroll-bar .range-handle::-moz-range-thumb {
        -webkit-appearance: none;
        /* Override default look */
        appearance: none;
        border-radius: 50%;
        border: none;
        width: 20px;
        /* Set a specific slider handle width */
        height: 20px;
        /* Slider handle height */
        background-image: url("assets/img/scollBarHandle-min.png");
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        /* Cursor on hover */ }
