#evaluation {
  height: 100%;
  width: 100%; }
  #evaluation .flex-1 {
    flex: 1; }
  #evaluation .sequence-container {
    padding-left: clamp(25px, 10vw, 50px);
    padding-right: clamp(25px, 10vw, 50px);
    padding-top: clamp(25px, 10vh, 50px);
    padding-bottom: clamp(25px, 10vh, 50px);
    width: 100%;
    height: auto;
    min-height: 100vh; }
  @media screen and (max-height: 500px) {
    #evaluation .sequence-container {
      display: block; } }
  #evaluation .fixed-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0; }
  #evaluation .content-container {
    padding: 50px;
    min-height: min(80vh, 800px); }
    #evaluation .content-container.last-content {
      min-height: 0;
      flex: 1;
      max-height: min(80vh, 800px); }
    #evaluation .content-container h1.title {
      color: white;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      padding: 20px 40px;
      display: inline-block; }
      #evaluation .content-container h1.title div {
        transform: rotate(-2deg); }
    #evaluation .content-container .action-content {
      margin: auto 0;
      width: 100%; }
  #evaluation .actions-container {
    text-align: center;
    height: 100%;
    width: 100%;
    max-width: 800px; }
    #evaluation .actions-container p.title {
      font-size: 1.6rem;
      font-weight: 700;
      margin: auto 0;
      padding: 30px 15px; }
    #evaluation .actions-container .action-text {
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem; }
    #evaluation .actions-container .action-button {
      font-size: 1.2rem;
      font-weight: 900;
      width: 50%;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat; }
    #evaluation .actions-container .submit-button {
      padding: 20px;
      color: white;
      margin-left: min(30px, 5%);
      background-color: black;
      border-radius: 50%;
      cursor: unset; }
      #evaluation .actions-container .submit-button:hover:not(:disabled) {
        background-color: #4510de;
        cursor: pointer; }
      #evaluation .actions-container .submit-button:disabled {
        opacity: 0.3; }
    #evaluation .actions-container .counter {
      font-size: 0.9rem;
      font-weight: 700;
      margin: auto 0;
      padding: 30px 15px 0 15px; }
      #evaluation .actions-container .counter.hidden {
        opacity: 0; }
    #evaluation .actions-container .dot-button img {
      transform: scale(1);
      transition: transform 250ms linear; }
    #evaluation .actions-container .dot-button:hover img {
      transform: scale(1.2); }
    #evaluation .actions-container .dot-button.selected img {
      transform: scale(1.2); }
    #evaluation .actions-container .score-container {
      width: 100%; }
      #evaluation .actions-container .score-container .arrow-ind {
        text-align: center;
        width: 12%; }
        #evaluation .actions-container .score-container .arrow-ind img {
          display: block;
          width: 80%;
          margin-left: 10%;
          max-width: 60px;
          margin-top: 30px; }
      #evaluation .actions-container .score-container .scores {
        flex: 1;
        justify-content: space-around;
        align-items: flex-start; }
        #evaluation .actions-container .score-container .scores .dot-button {
          width: 20%;
          max-width: 150px; }
          #evaluation .actions-container .score-container .scores .dot-button img {
            width: 65%; }
    #evaluation .actions-container .open-text {
      outline: none;
      width: 100%;
      resize: none;
      border: none;
      font-family: inherit;
      font-size: 1rem;
      background-repeat: no-repeat;
      background-size: 100% 3px;
      background-position: bottom;
      background-image: url("assets/img/barre_texte.png"); }
  #evaluation .actionsAnimation-enter {
    opacity: 0;
    transform: scale(0.9); }
  #evaluation .actionsAnimation-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 1000ms, transform 1000ms; }
  #evaluation .actionsAnimation-exit {
    opacity: 1;
    transform: scale(1); }
  #evaluation .actionsAnimation-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1000ms, transform 1000ms; }
  #evaluation .partners {
    color: white;
    width: 90%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    font-weight: 700; }
    #evaluation .partners .partner-container {
      flex: 1; }
      #evaluation .partners .partner-container p {
        margin-top: 0; }
      #evaluation .partners .partner-container img.partner-logo {
        height: 10vh;
        max-height: 50px;
        max-width: 80%;
        object-fit: contain;
        object-position: top; }
