.about-background-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10; }

#about {
  padding: min(10vh, 50px) min(15vw, 80px);
  overflow-y: auto;
  position: relative;
  z-index: 100; }
  #about .scroll-indicator {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: calc(min(10vh, 50px) + 60px);
    z-index: 500;
    background: linear-gradient(transparent, black); }
  #about .button-go-back {
    margin-left: auto;
    position: fixed;
    top: min(10vh, 50px);
    right: min(15vw, 80px); }
    #about .button-go-back .close .close-icon {
      height: 50px; }
  #about .content {
    flex: 1;
    padding-top: 30px;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
    #about .content section {
      padding-bottom: 80px; }
    #about .content .uppercase {
      text-transform: uppercase; }
    #about .content .title {
      margin-bottom: 50px;
      padding-left: 50px;
      font-size: 2.2rem; }
    #about .content a {
      color: white;
      text-decoration: underline; }
    #about .content .text {
      position: relative;
      padding-left: 50px;
      font-size: 1.2rem;
      font-weight: 700;
      max-width: 600px;
      margin-bottom: 40px; }
      #about .content .text .circular-button {
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center; }
        #about .content .text .circular-button img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center; }
    #about .content .two-columns {
      margin-left: 50px;
      font-size: 1.1rem; }
      #about .content .two-columns .col {
        width: 50%;
        padding-right: 30px; }
        #about .content .two-columns .col .logo {
          height: 70px;
          margin-bottom: 30px; }
          #about .content .two-columns .col .logo.small {
            height: 50px; }
