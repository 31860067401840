#choice {
  padding-left: clamp(25px, 10vw, 50px);
  padding-right: clamp(25px, 10vw, 50px);
  height: auto;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  overflow: auto;
  width: 100%;
  justify-content: space-between; }
  #choice .control-container {
    width: 100%;
    justify-content: space-between;
    margin-top: clamp(25px, 10vh, 50px); }
  #choice .content {
    flex: 1;
    padding: clamp(15px, 5vh, 30px) 0 0 0;
    margin-bottom: clamp(15px, 5vh, 30px); }
    #choice .content .content-text {
      font-weight: 700;
      font-size: clamp(1.4rem, 4vh, 2rem); }
  #choice .actions-container {
    padding-top: clamp(25px, 3vh, 50px);
    padding-bottom: clamp(50px, 5vh, 80px);
    margin: auto 0;
    text-align: center; }
    #choice .actions-container .action-text {
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem; }
    #choice .actions-container .action-button {
      font-size: clamp(1.2rem, 3vh, 1.8rem);
      font-weight: 700;
      padding: 15px;
      width: 50%;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat; }
      #choice .actions-container .action-button .icon {
        width: 80%;
        max-width: 80px;
        position: relative;
        margin: auto; }
        #choice .actions-container .action-button .icon img {
          object-fit: contain;
          object-position: top;
          height: 100%;
          width: 100%;
          opacity: 1;
          transition: opacity 250ms linear; }
        #choice .actions-container .action-button .icon img.on-hover {
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          transition: opacity 250ms linear; }
      #choice .actions-container .action-button:disabled {
        opacity: 0.3; }
      #choice .actions-container .action-button:hover .icon img {
        opacity: 0; }
      #choice .actions-container .action-button:hover .icon img.on-hover {
        opacity: 1; }
    #choice .actions-container .action-next {
      width: 100%;
      text-align: center;
      padding-top: 20px; }
      #choice .actions-container .action-next button {
        width: 80%;
        max-width: 500px;
        font-weight: 700;
        font-size: 1.2rem; }
  #choice .transition-container {
    flex: 1; }
  #choice .fixed-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0; }
  #choice .confirmation-enter {
    opacity: 0; }
  #choice .confirmation-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
    transition-delay: 250ms; }
  #choice .confirmation-exit {
    opacity: 1; }
  #choice .confirmation-exit-active {
    opacity: 0;
    transition: opacity 1000ms; }
  #choice .actionsAnimation-enter {
    opacity: 0;
    transform: scale(0.9); }
  #choice .actionsAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1000ms, transform 1000ms; }
  #choice .actionsAnimation-exit {
    opacity: 1; }
  #choice .actionsAnimation-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1000ms, transform 1000ms; }

.fake-choice {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px 30px 50px 40px;
  width: 50vw;
  max-width: 500px;
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0ms linear; }
  .fake-choice.show {
    transition-duration: 500ms;
    opacity: 1; }
  .fake-choice .text-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    font-size: clamp(1.3rem, 2.5vh, 1.8rem);
    /* Track */
    /* Handle */ }
    .fake-choice .text-container::-webkit-scrollbar {
      width: 2px; }
    .fake-choice .text-container::-webkit-scrollbar-track {
      background: white; }
    .fake-choice .text-container::-webkit-scrollbar-thumb {
      background: black; }
  .fake-choice .close-button-container {
    width: 100%;
    text-align: right;
    margin-bottom: 20px; }
    .fake-choice .close-button-container .close-icon {
      width: 30px;
      height: 30px;
      object-fit: contain;
      object-position: center; }

@media (max-width: 767px) {
  .fake-choice {
    width: 80vw !important; }
    .fake-choice .close-button-container {
      margin-bottom: 10px; } }
