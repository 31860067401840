.main-otherStories {
  height: auto;
  height: 100%;
  overflow: auto;
  width: 100%;
  justify-content: space-between; }
  .main-otherStories .control-container {
    width: 100%;
    justify-content: space-between; }
  .main-otherStories #other-choice {
    position: relative;
    padding-left: clamp(25px, 10vw, 50px);
    padding-right: clamp(25px, 10vw, 50px);
    padding-top: clamp(25px, 10vh, 50px);
    padding-bottom: clamp(15px, 5vh, 30px);
    min-height: 100%;
    height: auto;
    overflow: auto;
    width: 100%; }
  .main-otherStories .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none; }
    .main-otherStories .overlay img {
      width: 100%;
      height: 100%;
      object-position: center; }
  .main-otherStories .content {
    flex: 1;
    padding: clamp(15px, 5vh, 30px) 0 0 0; }
    .main-otherStories .content .content-text {
      font-weight: 700;
      font-size: 2rem;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      padding: 50px;
      margin-top: 10vh; }
  .main-otherStories .actions-container {
    padding-top: clamp(25px, 3vh, 50px);
    padding-bottom: clamp(50px, 5vh, 80px);
    margin: auto 0;
    text-align: center; }
    .main-otherStories .actions-container .title {
      font-size: 2rem;
      font-weight: 700; }
    .main-otherStories .actions-container .action-text {
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem; }
    .main-otherStories .actions-container .action-button {
      font-size: 1.2rem;
      font-weight: 900;
      padding: 15px;
      width: 50%;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat; }
      .main-otherStories .actions-container .action-button .icon {
        width: 80%;
        max-width: 80px;
        position: relative;
        margin: auto; }
        .main-otherStories .actions-container .action-button .icon img {
          object-fit: contain;
          object-position: top;
          height: 100%;
          width: 100%;
          opacity: 1;
          transition: opacity 250ms linear; }
        .main-otherStories .actions-container .action-button .icon img.on-hover {
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          transition: opacity 250ms linear; }
      .main-otherStories .actions-container .action-button:disabled {
        opacity: 0.3; }
      .main-otherStories .actions-container .action-button:hover .icon img {
        opacity: 0; }
      .main-otherStories .actions-container .action-button:hover .icon img.on-hover {
        opacity: 1; }
    .main-otherStories .actions-container .action-next {
      width: 100%;
      text-align: center;
      padding-top: 20px; }
      .main-otherStories .actions-container .action-next button {
        width: 80%;
        max-width: 500px;
        font-weight: 700;
        font-size: 1.2rem; }
  .main-otherStories .transition-container {
    flex: 1; }
  .main-otherStories .fixed-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0; }
  .main-otherStories .confirmation-enter {
    opacity: 0; }
  .main-otherStories .confirmation-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
    transition-delay: 250ms; }
  .main-otherStories .confirmation-exit {
    opacity: 1; }
  .main-otherStories .confirmation-exit-active {
    opacity: 0;
    transition: opacity 1000ms; }
  .main-otherStories .actionsAnimation-enter {
    opacity: 0;
    transform: scale(0.9); }
  .main-otherStories .actionsAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1000ms, transform 1000ms; }
  .main-otherStories .actionsAnimation-exit {
    opacity: 1; }
  .main-otherStories .actionsAnimation-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1000ms, transform 1000ms; }
